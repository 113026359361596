<template>
  <div class="main">
    <div class="wrap reset_wrap">
      <div class="left_wrap">
        <div class="image-title">
          <p>Welcome to</p>
          <p v-if="isSuplier" class="supplier">{{ $t("supplier.label_under_logo_login_screen") }}</p>
        </div>
      </div>
      <div class="right_wrap" v-if="!status">
        <div class="rs-80"></div>

        <v-form class="right_block reset_frm" @submit.prevent="submit" ref="form" lazy-validation>
          <h1 class="title-text mb-48 reset_inst">
            <span class="title-text-style">{{ $t("reset_pass.title_reset_password") }}</span>
          </h1>
          <div class="instruction mb-48 reset_inst">
            <span>{{ $t("reset_pass.description_reset_password") }}<br />{{ $t("reset_pass.description_enter_new_pass") }}</span>
          </div>
          <div class="input-block">
            <span class="input-title">{{ $t("reset_pass.label_password") }}</span>
            <div class="passBox">
              <input id="password" v-model="password" class="input-elic" :type="!showPass ? 'password' : 'text'" />
              <eye-icon :id="'password_eye'" :changeIcon="() => (this.showPass = !this.showPass)" />
              <span class="err-mess" style="display: none" id="password_error"></span>
              <img
                id="password_img"
                class="validWarning"
                src="/img/icons/warning.svg"
                style="position: absolute; right: 6px; display: none"
              />
            </div>
          </div>

          <div class="input-block mt-40">
            <span class="input-title">{{ $t("reset_pass.label_password_confirm") }}</span>
            <div class="passBox">
              <input
                id="confirmPassword"
                v-model="confirmPassword"
                class="input-elic"
                :type="!showPassConfirm ? 'password' : 'text'"
              />
              <eye-icon id="confirmPassword_eye" :changeIcon="() => (this.showPassConfirm = !this.showPassConfirm)" />
              <span class="err-mess" style="display: none" id="confirmPassword_error"></span>
              <img
                id="confirmPassword_img"
                class="validWarning"
                src="/img/icons/warning.svg"
                style="position: absolute; right: 6px; display: none"
              />
            </div>
          </div>
          <div class="instruction reset_inst mt-44">
            <ul>
              {{ $t("reset_pass.description_condition_password") }}
              <li>{{ $t("reset_pass.description_number_charactor") }}</li>
              <li>{{ $t("reset_pass.description_rule_password") }}</li>
            </ul>
          </div>
          <input class="btn-submit mt-48" type="submit" :value="buttonReset" />
        </v-form>
      </div>
      <div class="reset_boxRight" v-if="status">
        <div class="reset_right">
          <h1 class="title-text reset_inst">
            <span class="title-text-style">{{ $t("reset_pass.title_reseted") }}</span>
          </h1>
          <div class="instruction mt-48 reset_inst">
            <span>{{ $t("reset_pass.description_login_new_pass") }}</span>
          </div>
          <input
            @click="redirectIntoLogin"
            class="btn-submit mt-47"
            type="submit"
            :value="buttonRedirect"
          />
        </div>
      </div>

      <error-popup :dialog="showError" :message="message" @submit="handleClose" :confirmText="$t('list_menu.label_decision')" />
      <question-popup
        :dialog="showConfirm"
        :message="confirmMessage"
        :titlePopupConfirm="titlePopupConfirm"
        @submit="handleResetPass"
        @close="showConfirm = false"
        :closeText="closeText"
        :confirmText="$t('list_menu.label_decision')"
      />
    </div>
  </div>
</template>

<script>
import validate from '@/utils/validate';
import { resetPasswordApi, checkResetPasswordTokenApi } from '@/api/auth';
import { validateRequired } from '@/api/others';
import EyeIcon from '@/components/commonApp/EyeIcon.vue';
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import { getErrorMessge } from '@/utils/messageHandle';
import QuestionPopup from '@/components/dialogs/question-popup.vue';

export default {
  name: 'ChangePassword',
  components: {
    EyeIcon,
    ErrorPopup,
    QuestionPopup,
  },
  data() {
    return {
      title: '',
      desc: '',
      password: '',
      confirmPassword: '',
      passRules: [
        (v) => !!v,
        (value) => value && value.length >= 8 && value.length <= 20,
        (v) => validate.regexPassword.test(v),
      ],
      confirmPasswordRules: [(value) => !!value, (value) => value && value === this.password],
      showConfirm: false,
      message: '',
      messageCheckToken: '',
      showPass: false,
      showPassConfirm: false,
      status: false,
      match: false,
      getVars: {},
      showError: false
    };
  },
  computed: {
    buttonReset() {
      return this.$t("reset_pass.button_reset_password");
    },
    buttonRedirect() {
      return this.$t("reset_pass.button_back_login");
    },
    confirmMessage() {
      return this.$t("reset_pass.description_confirm_reset_pass");
    },
    closeText() {
      return this.$t('popup.button_cancel')
    },
    titlePopupConfirm() {
      return this.$t('register_reduction.title_popup_confirm')
    },
    isSuplier() {
      return this.$route?.query?.primary_uuid
    }
  },
  methods: {
    submit() {
      if (!validateRequired(['password', 'confirmPassword'])) {
        return;
      }
      if (
        this.password != this.confirmPassword ||
        this.password.length > 20 ||
        !validate.regexPassword.test(this.password)
      ) {
        this.message = this.$t("reset_pass.description_password_incorrect")
        this.showError = true;
      } else {
        this.showConfirm = true;
      }
    },
    handleClose() {
      this.showError = false;
    },
    handleTogglePassword() {
      this.showPass = !this.showPass;
    },
    handleTogglePasswordConfirm() {
      this.showPassConfirm = !this.showPassConfirm;
    },
    handleResetPass() {
      resetPasswordApi({
        email: this.getVars.email,
        token: this.getVars.token,
        password: this.password.trim(),
        password_confirmation: this.confirmPassword.trim(),
        primary_uuid: this.$route?.query?.primary_uuid
      })
        .then(() => {
          this.showConfirm = false;
          this.status = true;
          this.title = this.$t("reset_pass.title_reset_success");
          this.desc = this.$t("reset_pass.description_login_new_pass");
        })
        .catch((err) => {
          this.showConfirm = false;
          this.title = err.message;
          this.message = getErrorMessge(err);
          this.showError = true;
        });
    },
    redirectIntoLogin() {
      this.$router.push({ name: 'Login', query: { primary_uuid: this.$route.query?.primary_uuid } });
    }
  },
  mounted() {
    const email = this.$route.query.email;
    const token = this.$route.query.token;
    const lang = this.$route.query.language;
    this.getVars = {
      email,
      token,
    };

    if(lang) {
      this.$i18n.locale = lang
      localStorage.setItem('language', lang)
    }

    checkResetPasswordTokenApi({
      token: this.getVars.token,
    }).catch((err) => {
      this.$store.dispatch('userData/updateMessage', getErrorMessge(err));
      this.$router.push({ name: 'Login', query: { primary_uuid: this.$route.query?.primary_uuid } });
    });
  },
};
</script>

<style scoped lang="scss">
@import './LoginStyle/index.scss';

.main {
  // min-height: 550px;
  min-height: calc(100vh - 88px);
  height: unset;
}
.none {
  display: none;
}

.block {
  display: block;
}

.passBox {
  position: relative;
  .input-icon {
    position: absolute;
    display: flex;
    top: 14px;

    flex-direction: row;
    justify-content: center;
    align-items: center;
    right: 0;
    height: 40px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px;
    cursor: pointer;
  }
  .input-icon-eye {
    width: 36px;
    height: 31px;
    padding: 5px;
  }
}
.right_wrap {
  height: 727px !important;
}
</style>
